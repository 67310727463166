// Basic colors

$blue: #608496;
$blue2: #005da3;
$blue3: #005C9E;
$white: #fff;
$gray: #465259;
$light-grey: rgba(224, 231, 235, 0.4);
$light-grey2: #688394;
$light-grey3: #f4f4f4;
$light-orange: #ffe5d2;
$orange: #f37033;
$orange2: #e37743;
$orange3 : #ff7d1e;
$light-green: #ecfdf3;
$green: #1d6f42;
$green2: #027a48;
$light-red: #ffeef0;
$red: #bd3333;


// $light-blue: #0064fa;
// $light-blue2: #4d94ff;
// $light-grey: #eceff2;
// $light-grey2: #f5f8fa;
// $light-grey3: #c2cbf8;
// $light-grey-divider: #e0e4fc;
// $dark-grey-divider: #8c91a0;
// $light-green: #40f2d4;
// $light-green2: #8fffec;

// $orange2: #ffb878;
// $orange3: #ff912f;


// // font family names
// $as-bold: AvertaStd-Bold;
// $as-bold-italic: AvertaStd-BoldItalic;
// $as-extra-bold: AvertaStd-ExtraBold;
// $as-extra-bold-italic: AvertaStd-ExtraBoldItalic;
// $as-regular: AvertaStd-Regular;
// $as-regular-italic: AvertaStd-RegularItalic;

//typography
$size-counter: 80px;
$size-h1-titles: 50px;
$size-h2: 35px;
$size-h3: 26px;
$size-important-amounts: 26px;
$size-h4: 22px;
$size-h5: 18px;
$size-button-text: 16px;
$size-body-text: 16px;
$size-small-text: 14px;