@import "src/styles/variables";
@import "src/styles/mixins/typography";

.navbar{
    height: 110px;
    position: relative;
    background: $light-grey3;
    border-bottom: 10px solid $orange;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;

    & .page{
        width: 1260px;
        margin: 0 auto;
        position: relative;
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .bff-logo{
            
            & img {
                width: 200px;
                cursor: pointer;
            }
        }

        & .account{
            display: flex;
            align-items: center;
            
            &__username{
                color: $blue3;
                font-size: 18px;
            }

            &__avatar{
                color: $blue3;
                font-size: 40px;
                margin-left: 15px;
                cursor: pointer;
            }
        }

    }
}