@import "src/styles/variables";
@import "src/styles/mixins/typography";

.fileEditFormComponent{

    color: $gray;

    & .firstRow{

        display: flex;
        justify-content: space-between;
        
        &__left{
            display: flex;
            align-items: center;

            & span{
                margin-right: 10px;
                font-size: 16px;
                color: $gray;
                font-weight: 600;
            }

            & .statusBadge{

                margin-left: 20px;
                
                & span{
                    font-size: 16px;
                }
            }
        }


        &__right{
            
            display: flex;

            & button{
                
                & svg {
                    margin-right: 10px;
                }
            }
        }
    }

    & .fileEditForm, & .fileEditView{
        padding-top: 50px;
        padding-bottom: 100px;

        & .ant-col{
            padding-right: 10px !important;
        }

        & .form-item{

            & label{
                display: block;
                font-size: 14px;
                text-align: left;
                margin-bottom: 5px;
            }
        }

        &__section{
            margin-bottom: 50px;

            & .ant-divider{
                margin-bottom: 40px;
            }
        }
    }

    & .fileEditView{
        padding-top: 50px;
        padding-bottom: 100px;

        &__section{
            margin-bottom: 50px;

            & .ant-divider{
                margin-bottom: 40px;
            }
        }

        & .label{
            height: 24px;
            font-weight: 600;
            color: #465259  !important
        }
    }
}