@import "src/styles/variables";
@import "src/styles/mixins/typography";


.errorsComponent{
    .cut-text { 
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 80%; 
        white-space: nowrap;
      }
    
      .info{
        color: $orange;
      }
    
      .errorMessageContainer{
        display: flex;
      }
}