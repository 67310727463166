@import "src/styles/variables";
@import "src/styles/mixins/typography";


.responseData{

    &__label{
        font-weight: 600;
        color: $gray;
    }

    &__value{
        color: $gray;
    }
}