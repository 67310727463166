@import "src/styles/variables";
@import "src/styles/mixins/typography";


.statusBadge {

  display: flex;
  justify-content: center;

  & .application-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px 2px 6px;
    border-radius: 16px;

    & span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .application-badge.start-badge {
    background: $light-green;
    width: fit-content;

    & span {
      color: $green2;
    }
  }

  .application-badge.reject-badge {
    background: $light-red;
    width: fit-content;

    & span {
      color: $red;
    }
  }

  .application-badge.pending-badge {
    background: $light-orange;
    width: fit-content;

    & span {
      color: $orange3;
    }
  }

}