@import "src/styles/variables";
@import "src/styles/mixins/typography";

.historyScreen{

    &__container{
        padding-top: 50px;
        margin-bottom: 100px;

        & .title{
            font-size: $size-h2;
            color: $gray;
        }

        & .firstRow{
            padding-top: 30px;
        }

        & .secondRow{
            padding-top: 50px;
        }

        & .thirdRow{
            padding-top: 50px;
        }
    }
}