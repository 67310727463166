@import "src/styles/variables";
@import "src/styles/mixins/typography";

.loginComponent{
    display: flex;
    align-items: center;
    width: 390px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 80px;

    & .content{
        width: 390px;

        & .logo{
            width: 200px;
            margin-bottom: 60px;
            float: right;
        }

        & .clear{
            clear: both;
        }

        & .product-slogan{
            font-size: 36px;
            text-align: left;
            margin-bottom: 10px;
            //font-family: 'DinotRegular';

            & .bff{
                color: $blue;
                letter-spacing: 0.20rem;
            }

            & .slash{
                transform: rotate(5deg);
                display: inline-block;
                color: $blue2;
                letter-spacing: 0.50rem;
            }

            & .reporting{
                color: $orange;
            }
        }

        & .login-form{
            background-color: $light-grey;
            border: 1px solid #E0E7EB;
            padding: 30px;
            border-radius: 5px;

            & .form-item {
                margin-bottom: 15px;

                & label{
                    display: block;
                    text-transform: uppercase;
                    font-size: 14px;
                    color: $light-grey2;
                    text-align: left;
                    margin-bottom: 5px;
                }

                & input[type=text], input[type=password],  select {
                    height: 44px;
                    border: 1px solid #E0E7EB;
                    border-radius: 0;
                    width: 100%;
                    padding: 0 10px;
                    font-size: 15px;
                    border-radius: 5px;
                }

                &  .login-btn {
                    width: 100%;
                    height: 44px;
                    background-color: $blue3;
                    color: $white;
                    font-family: 'DinotRegular';
                    text-transform: uppercase;
                    float: left;
                    border: none;
                    font-size: 15px;
                    cursor: pointer;
                    margin-top: 10px;
                    border-radius: 5px;
                    letter-spacing: 0.10rem;
                    text-align: center;
                }

                & .clear {
                    clear: both;
                }
            }
        }
    }
}