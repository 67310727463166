@import "src/styles/variables";
@import "src/styles/mixins/typography";

.fileData{

    &__label{
        font-weight: 600;
        color: $gray;
    }

    &__value{
        color: $gray;
    }

    & .badgeContainer{
        display: flex;
        justify-content: end;
        margin-right: 50px;
        margin-top: 20px;
        padding-bottom: 30px;
    }
}