@import "src/styles/variables";
@import "src/styles/mixins/typography";

.treatButton{
    display: flex;
    justify-content: space-between;
    width: 90px;
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    align-items: center;

    & span{
    }

    & .info{
        color: $orange;
        width: 22px;
    }

    & .check{
        color: #027a48;
        width: 22px;
    }
}

.treatButton .disabled{
    opacity: 0.5;
}

.treatButton:hover{
    background-color: $orange;
    color: $white;
    border: 1px solid $orange;

    & svg{
        color: $white;
    }
}

.treatButton.disabled{
    pointer-events: none;
    opacity: 0.5;
}