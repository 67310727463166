@import "src/styles/variables";
@import "src/styles/mixins/typography";

.ant-table-cell{
  max-width: 200px !important;
}

.MuiMenuItem-root:focus{
  background-color: $white !important;
}

.MuiMenuItem-root:hover{
    background-color: $blue3 !important;
    color: $white !important;

    & .MuiTypography-root{
        color: $white !important;
    }
  }

.MuiMenu-list{
  padding: 0 !important;
}

.MuiTypography-root{
    color: $blue3 !important;
    font-weight: 600 !important;
    display: flex;
    
    & svg{
        margin-left: 10px;
    }
}


.ant-input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number {
  background: white;
  padding: 18px 14px;
  border-radius: 10px;
  height: 55px;
  font-size: 18px !important;;
  text-align: left;
}

.ant-input:hover, .ant-select-selector:hover, .ant-picker:hover{
  border: 1px solid $orange !important;
}


.ant-form-item-label>label {
  font-weight: 600;
  font-size: 1.13rem !important;
  letter-spacing: 0.00938em;
  color: $gray !important;
}



.ant-form-item label:after {
  content: none !important;
}

.ant-picker {
  background: white;
  padding: 18px 12px;
  border-radius: 10px;
  font-size: 18px !important;
  height: 55px;
  width: 100%;

  & input{
    font-size: 18px !important;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 18px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 18px !important;
  overflow: visible !important;
  color: #909093 !important;
  font-size: 18px !important;
  font-weight: normal !important;
}

.ant-layout{
  background: white !important
}

.ant-table-cell{
  color: $gray !important;
}

.ant-pagination .ant-pagination-item-active a{
  color: $orange !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: $orange !important;
}

.ant-collapse-header{
  display: flex !important;
  align-items: center !important;
}

.ant-collapse-header-text{
  color: $gray;
  font-weight: 600;
  font-size: $size-h5;
}

.ant-collapse-content-box{
  padding: 0 !important;
}

.ant-table-body{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none; 
}

.ant-table-body::-webkit-scrollbar{
  display: none;  /* Safari and Chrome */
}

.ant-divider-inner-text{
  font-size: $size-h4;
  color: $gray !important;
}

.ant-form-item-label{

  & label{
    font-size: 14px !important;
    color: $gray !important;
    text-align: left;
    margin-bottom: 5px;
  }

}

.ant-pagination{
  float: right !important;
  margin-top: 20px !important;
}

.ant-spin-dot-item {
  background-color: $orange  !important;
}

.ant-tooltip-inner {
  background-color: $light-grey3 !important;
  width: 600px !important;
  border: 1px solid $orange !important;
  color: $gray !important;
}

.ant-tooltip .ant-tooltip-arrow:before {
  background-color: $orange  !important;

}

.ant-tooltip-placement-bottom .ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: $light-grey3  !important;
}

//MUI
.MuiTooltip-tooltip{
  font-size: 16px !important;
  background-color: $light-grey3 !important;
  color: $gray !important;
}

@media (min-width: 1200px){
  .MuiContainer-root {
      max-width: 1300px !important;
  }
}