@import "src/styles/variables";
@import "src/styles/mixins/typography";

.alertsTableComponent{
  &__filters{

  }

  &__buttons{

    & svg{
      font-size: 25px;
      cursor: pointer;
      color: $green;
      margin-right: 20px;
      font-weight: 500;
    }
  }

  .download_button{
    padding-left: 2px;
    margin-left: 5px;
    color: #f37033;

  }
  .download_button:hover{
    cursor: pointer;
  }

  .cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 80%; 
    white-space: nowrap;
  }

  .info{
    color: $orange;
  }

  .errorMessageContainer{
    display: flex;
  }
}

.filterComponent{

  &__filters{

  }

  &__buttons{

    & svg{
      font-size: 25px;
      cursor: pointer;
      color: $green;
      margin-right: 20px;
      font-weight: 500;
    }

  }
}