@import "src/styles/mixins/typography";

.btn:disabled {
    opacity: .5;
}
  
.btn {
    height: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    border: none;
    font-size: 18px;

    & .withIcon{
        display: flex;
        align-items: center;
    }

    &--orange{
        background-color: $orange2;
    }

    &--orange:hover{
        opacity: 0.9;
        color: white !important;
    }

    &--blue{
        background-color: $blue3;
    }

    &--blue:hover{
        opacity: 0.9;
        color: white !important;
    }
}